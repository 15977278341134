import React, { useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import "../styles/order.css";
import personicon from "../assets/personicon.png";
import foldericon from "../assets/foldericon.png";
import locationicon from "../assets/locationicon.png";
import { FormCheck } from "react-bootstrap";
import {
  COLUMNS_ORDER_PRODUCT,
  COLUMNS_ORDER_BOOKING,
  COLUMNS_ORDER_BOARDING,
  COLUMNS_ORDER_VIDEO,
  COLUMNS_ADOPTION,
} from "../utils/Col";
import sorticon from "../assets/sort.png";
import { usePagination, useTable, useSortBy } from "react-table";
import { useLocation } from "react-router-dom";
import { format, set } from "date-fns";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import backicon from "../assets/back.png";
// import Select from "react-select";

const orderStatusList = [
  "Pending Payment",
  "Processing",
  "Out For Delivery",
  "Completed",
  "Failed",
  "Cancel Request",
];
const actions = orderStatusList.map((e, index) => (
  <option key={index}>{e}</option>
));
const action = (
  <select
    name=""
    id=""
    className="card-btn"
    style={{
      backgroundColor: "rgba(94, 99, 102, 0.08)",
      color: "#8B8D97",
    }}
    disabled
  >
    {actions}
  </select>
);

export default function Order() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state.data);
  const [presviosData, setPreviousData] = useState(
    location.state ? location.state?.data : ""
  );
  const [prodClass, setProdClass] = useState(true);
  const [bookClass, setBookClass] = useState(false);
  const [boardClass, setBoardClass] = useState(false);
  const [vidClass, setVidClass] = useState(false);
  const [adopClass, setAdopClass] = useState(false);
  console.log(presviosData);
  const [orderData, setOrderData] = useState(presviosData?.cart?.cartProducts);
  orderData?.map((item) => {
    // item.product.title = item.product.title.slice(0, 25).padEnd(30, ".");
    // item.checkbox = check;
    item.total_single = item.price * item.quantity - item.discount_price;
    item.action = action;
  });
  // console.log(orderData);
  const data = useMemo(() => orderData, [orderData]);
  const [columns, setColumns] = useState(COLUMNS_ORDER_PRODUCT);
  const tableinstance = useTable({ columns, data }, useSortBy, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    gotoPage,
    pageOptions,
    state,
    pageCount,
    setPageSize,
    prepareRow,
  } = tableinstance;
  // const customColor = (baseStyles) => ({
  //   ...baseStyles,
  //   backgroundColor: "rgba(94, 99, 102, 0.08);",
  //   height: "12px",
  // });
  return (
    <div>
      <div className="order-content">
        <div className="order-header">
          <div className="header-div">
            <div className="back">
              <button className="back-btn" onClick={() => navigate(-1)}>
                <img src={backicon} alt="back" className="back-icon" />
              </button>
            </div>
            <div className="oheader">
              <span className="order-heading">Order Number</span>
              <span className="head-value">{presviosData?.orderID}</span>
            </div>
            <div className="oheader">
              <span className="order-heading">Order Date</span>
              <span className="head-value">
                {format(
                  new Date(presviosData?.createdAt),
                  "dd MMM yyyy h:mm a"
                )}
              </span>
            </div>
          </div>
          <div className="header-div">
            {/* <div className="mark">
              <span className="btn-text">Mark as complete</span>
            </div> */}
            <select
              name=""
              id=""
              className="mark"
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              <option value="">{presviosData.order_status}</option>
            </select>
            {/* <div className="note">
              <span className="btn-text">Delivery Note</span>
            </div> */}
          </div>
        </div>
        <div className="order-body">
          <div className="cards">
            <div className="card">
              <div className="ctop">
                <div className="card-iconholder">
                  <img
                    src={personicon}
                    alt="personicon"
                    className="card-icon"
                  />
                </div>
                <div className="ccustomer">
                  <div>
                    <span className="chead">
                      {presviosData?.cart?.customer?.first_name
                        ? presviosData.cart.customer.first_name
                        : ""}
                    </span>
                    <div>
                      {/* <span className="chead">Customer Since</span>
                      <span className="ctext">12 September 2022</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cbottem">
                <div className="btm-part">
                  <span className="chead">Phone</span>
                  <span className="ctext">
                    {presviosData?.cart?.customer?.phone
                      ? presviosData.cart.customer.phone
                      : ""}
                  </span>
                </div>
                <div className="btm-part">
                  <span className="chead">Email</span>
                  <span className="ctext">
                    {presviosData?.cart?.customer?.email
                      ? presviosData.cart.customer.email
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="ctop">
                <div className="card-iconholder">
                  <img
                    src={locationicon}
                    alt="personicon"
                    className="card-icon"
                  />
                </div>
              </div>
              <div className="cbottem">
                <div className="btm-part">
                  <span className="chead">Billing Address</span>
                  <span className="ctext">
                    {presviosData?.address
                      ? presviosData?.address?.buildingNum +
                        ", " +
                        presviosData?.address?.buildingName +
                        ", " +
                        presviosData?.address?.address +
                        ", " +
                        presviosData?.address?.city +
                        " " +
                        presviosData?.address?.state +
                        ", " +
                        presviosData?.address?.country +
                        ", " +
                        presviosData?.address?.pincode
                      : "No address available"}
                  </span>
                </div>
                <div className="btm-part"></div>
              </div>
            </div>
          </div>
          <div className="order-table-div">
            <div className="tab-header">
              <span
                className={
                  prodClass ? "table-heading table-heading-bg" : "table-heading"
                }
                onClick={() => {
                  setOrderData(presviosData?.cart.cartProducts);
                  setColumns(COLUMNS_ORDER_PRODUCT);
                  setBookClass(false);
                  setBoardClass(false);
                  setVidClass(false);
                  setAdopClass(false);
                  setProdClass(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Product Items{" "}
                <span style={{ fontWeight: 600 }}>
                  {presviosData?.cart?.cartProducts?.length}
                </span>
              </span>
              <span
                className={
                  bookClass ? "table-heading table-heading-bg" : "table-heading"
                }
                onClick={() => {
                  setOrderData(presviosData?.cart?.slotReservations);
                  setColumns(COLUMNS_ORDER_BOOKING);
                  setProdClass(false);
                  setBoardClass(false);
                  setVidClass(false);
                  setAdopClass(false);
                  setBookClass(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Booking Items{" "}
                <span style={{ fontWeight: 600 }}>
                  {presviosData?.cart?.slotReservations?.length}
                </span>
              </span>
              <span
                className={
                  boardClass
                    ? "table-heading table-heading-bg"
                    : "table-heading"
                }
                onClick={() => {
                  setOrderData(presviosData?.cart?.onboardingCartItems);
                  setColumns(COLUMNS_ORDER_BOARDING);
                  setProdClass(false);
                  setBookClass(false);
                  setVidClass(false);
                  setAdopClass(false);
                  setBoardClass(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Boarding Items{" "}
                <span style={{ fontWeight: 600 }}>
                  {presviosData?.cart?.onboardingCartItems?.length}
                </span>
              </span>
              <span
                className={
                  vidClass ? "table-heading table-heading-bg" : "table-heading"
                }
                onClick={() => {
                  setOrderData(presviosData?.cart?.slotReservation_vats);
                  setColumns(COLUMNS_ORDER_VIDEO);
                  setProdClass(false);
                  setBookClass(false);
                  setBoardClass(false);
                  setVidClass(true);
                  setAdopClass(false);
                }}
                style={{ cursor: "pointer" }}
              >
                Video Calls Items{" "}
                <span style={{ fontWeight: 600 }}>
                  {presviosData?.cart?.slotReservation_vats?.length}
                </span>
              </span>
              <span
                className={
                  adopClass ? "table-heading table-heading-bg" : "table-heading"
                }
                onClick={() => {
                  setOrderData(presviosData?.cart?.cartAdoptionPets);
                  setColumns(COLUMNS_ADOPTION);
                  setProdClass(false);
                  setBookClass(false);
                  setBoardClass(false);
                  setVidClass(false);
                  setAdopClass(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Adoption Items {""}
                <span style={{ fontWeight: 600 }}>
                  {presviosData?.cart?.cartAdoptionPets?.length}
                </span>
              </span>
            </div>
            <table {...getTableProps()} className="order-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="order-table-head order-table-row ctext"
                      >
                        {column.render("Header")}
                        {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="tr"
                      style={{ cursor: "pointer" }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="order-table-row chead"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="content-footer">
            <div className="footer-left">
              <div className="footer-card">
                <div className="footer-ctop">
                  <div className="card-iconholder">
                    <img
                      src={foldericon}
                      alt="personicon"
                      className="card-icon"
                    />
                  </div>
                  <div className="c-btns">
                    {/* <button className="card-btn" style={{ color: "#8B8D97" }}>
                      Capture
                    </button> */}
                    {/* <select
                      name=""
                      id=""
                      className="card-btn"
                      style={{
                        backgroundColor: "rgba(94, 99, 102, 0.08)",
                        color: "#8B8D97",
                      }}
                    >
                      <option value="" className="card-btn">
                        Capture
                      </option>
                      <option value="" className="card-btn">
                        Void
                      </option>
                    </select>
                    <button
                      className="card-btn"
                      style={{ backgroundColor: "#dcf2c0", color: "#1C1D22" }}
                    >
                      Process
                    </button> */}
                  </div>
                </div>
                <div className="cbottem">
                  <div className="btm-part">
                    <span className="chead">Payment Method</span>
                    <span className="ctext">
                      {presviosData?.payment?.option}
                    </span>
                  </div>
                  <div className="btm-part">
                    <span className="chead">Refund</span>
                    <span className="ctext">
                      AED {presviosData?.refund_price?.toFixed(2)}
                    </span>
                  </div>
                  <div className="btm-part">
                    <span className="chead">Deduct Amount</span>
                    <span className="ctext">
                      {/* AED {""}
                      {Number(
                        presviosData?.total_price + presviosData?.refund_price
                      ).toFixed(2)} */}
                      AED {presviosData?.total_price?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="footer-card">
                <div className="order-trail">
                  <span className="chead">Order Trail</span>
                  {presviosData?.order_trails
                    ?.sort(
                      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    )
                    .map((item, index) => (
                      <span className="ctext" key={index}>
                        {format(new Date(item.createdAt), "h:mm a ")}
                        {item.status}
                      </span>
                    ))}
                </div>
              </div>
            </div>
            <div className="footer-right">
              {/* <div className="coupon">
                <input
                  type="text"
                  className="coupon-inp"
                  placeholder="Enter Coupon"
                />
                <button className="coupon-btn">Add Coupon</button>
              </div> */}
              <div className="total-card">
                <div className="order-total">
                  <span className="total-row">
                    <span className="chead">Item Sub Total:</span>
                    <span className="ctext">
                      AED{" "}
                      {(
                        presviosData?.sub_price + presviosData?.redeemablePoint
                      )?.toFixed(2)}
                    </span>
                  </span>
                  <span className="total-row">
                    <span className="chead">Shipping:</span>
                    <span className="ctext">
                      AED {presviosData?.cart.shipment?.toFixed(2)}
                    </span>
                  </span>
                  <span className="total-row">
                    <span className="chead">VAT:</span>
                    <span className="ctext">
                      AED {presviosData?.vat_price?.toFixed(2)}
                    </span>
                  </span>
                  <span className="total-row">
                    <span className="chead">Discount:</span>
                    <span className="ctext">
                      AED {presviosData?.discount_price?.toFixed(2)}
                    </span>
                  </span>
                  {presviosData?.redeemablePoint ? (
                    <span className="total-row">
                      <span className="chead">Redeem Points:</span>
                      <span className="ctext">
                        {presviosData?.redeemablePoint?.toFixed(2)}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}

                  <span className="total-row">
                    <span className="chead">Order Total:</span>
                    <span className="ctext">
                      AED {presviosData?.total_price?.toFixed(2)}
                    </span>
                  </span>
                </div>
                <div className="order-total">
                  <span className="total-row">
                    <span className="chead">Paid:</span>
                    <span className="ctext">
                      AED {presviosData?.total_price?.toFixed(2)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
